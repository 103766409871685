import store from '../store'
import { Renderer } from '@unseenco/taxi'
import { Core, Emitter, AnimationManager } from '../core'
import { Splits, Menu, Smooth, Slider } from '../components'
import { qs, qsa, getid, formFocus, growTextarea } from '../utils'
import { ScrollTrigger } from '../vendor/ScrollTrigger'
import moment from 'moment'
import gsap from 'gsap'

export default class Default extends Renderer {
  constructor(opt = {}) {
    super(opt)

    this.core = new Core()

    this.state = {
      animateIn: false,
      header: false,
    }

    gsap.registerPlugin(ScrollTrigger)
  }

  onEnter() {
    const { sniff, body } = store
    if (sniff.isDevice) body.classList.add('is-device')
    store.page = this.page
  }

  onEnterCompleted() {
    this.on()
    if (this.core.ready) this.ready()
  }

  ready = () => {
    this.getDom()
    this.components()
    const animations = new AnimationManager()

    animations.in()
    animations.onScroll()

    ScrollTrigger.refresh()
  }

  readyCompleted = () => {
    store.flags.locked = false
  }

  getDom() {
    this.dom = {
      deskMenu: qs('.header-menu'),
      mobMenu: qs('.menu'),
      footer: qs('.footer'),
      open: qs('.burger'),
      close: qs('.close-menu'),
      hospots: qs('.hotspots'),
      scroll: qs('.heroHome-scroll'),
    }
  }

  components() {
    const { currentLocation } = this.core.taxi
    const { deskMenu, mobMenu, footer, open, close } = this.dom

    this.spliText = new Splits()

    this.menu = new Menu({
      deskMenu,
      mobMenu,
      footer,
      url: currentLocation.href,
      open,
      close,
    })

    this.addTime()
    this.accordion()
    this.map()
    this.addSlider()
    this.hotspots()
    this.tabs()
    this.building()
    this.bgVideo()
    this.scrollTo()
    this.textSlider()
  }

  addTime() {
    //const now = new Date()
    const now = moment()
    const localTime = now.format('HH:mm')
    const hour = parseInt(localTime.split(':')[0])

    const items = qsa('.item')
    this.period = ''
    let flag = 0

    if (items[0]) {
      items.forEach((item) => {
        item.classList.remove('-active')
      })

      items.forEach((item) => {
        item.addEventListener('click', (e) => {
          const target = e.target
          const item = target.innerHTML
          const current = flag + 1 <= items.length - 1 ? flag + 1 : 0

          target.classList.remove('-active')
          document.body.classList.remove(this.period)
          items[current].classList.add('-active')
          flag = current
          this.period = `-${items[current].innerHTML}`
          document.body.classList.add(this.period)
        })
      })
    }

    if (hour >= 5 && hour < 12) {
      this.period = '-morning'
      items[0] && items[0].classList.add('-active')
      flag = 0
    } else if (hour >= 12 && hour < 18) {
      this.period = '-afternoon'
      items[0] && items[1].classList.add('-active')
      flag = 1
    } else {
      this.period = '-evening'
      items[0] && items[2].classList.add('-active')
      flag = 2
    }

    document.body.classList.add(this.period)
  }

  scrollTo() {
    const { scroll } = this.dom

    if (!scroll) return

    let scrollPos = store.breakpoints.M_UP
      ? store.sizes.vh / 1.5
      : store.sizes.vh / 2.5

    scroll.addEventListener('click', () => {
      this.core.scroll.scroll.scrollTo(scrollPos, {
        duration: 0.6,
      })
    })
  }

  textSlider() {
    const textSlider = qs('.textSlider')

    if (!textSlider) return

    const slides = qsa('.textSlider-item', textSlider)
    const slideLeft = qs('.controls-prev', textSlider)
    const slideRight = qs('.controls-next', textSlider)
    const bullets = qsa('.bullets-item ', textSlider)
    let index = 0

    const animate = () => {
      gsap.to('.textSlider-item.-active .textSlider-title', {
        y: -20,
        autoAlpha: 0,
        duration: 0.3,
      })
      gsap.to('.textSlider-item.-active .textSlider-text', {
        y: -20,
        autoAlpha: 0,
        duration: 0.3,
        delay: 0.1,
        onComplete: () => {
          slides.forEach((slide) => {
            slide.classList.remove('-active')
          })

          slides[index].classList.add('-active')

          gsap.fromTo(
            '.textSlider-item.-active .textSlider-title',
            { y: 20, autoAlpha: 0 },
            { y: 0, autoAlpha: 1, duration: 0.3 },
          )
          gsap.fromTo(
            '.textSlider-item.-active .textSlider-text',
            { y: 20, autoAlpha: 0 },
            { y: 0, autoAlpha: 1, duration: 0.3, delay: 0.1 },
          )
        },
      })

      bullets.forEach((bullet) => {
        bullet.classList.remove('-active')
      })

      bullets[index].classList.add('-active')
    }

    slideLeft.addEventListener('click', () => {
      const flag = index

      if (index === 0) {
        index = 0
      } else {
        index -= 1
      }

      if (flag !== index) animate()
    })

    slideRight.addEventListener('click', () => {
      const flag = index

      if (index === slides.length - 1) {
        index = slides.length - 1
      } else {
        index += 1
      }

      if (flag !== index) animate()
    })
  }

  map() {
    const mapItems = qsa('[data-map]')
    const map = qs('.map-asset')

    mapItems.forEach((item) => {
      item.addEventListener('mouseenter', (el) => {
        const target = el.target
        const id = target.dataset.map
        const current = getid(id)
        gsap.to(current, { fill: '#fff', duration: 0.3 })
      })

      item.addEventListener('mouseleave', (el) => {
        const target = el.target
        const id = target.dataset.map
        const current = getid(id)
        gsap.to(current, { fill: '#1B5265', duration: 0.3 })
      })
    })
  }

  bgVideo() {
    const video = qs('video')

    if (video) video.play()
  }

  addSlider() {
    const slider = qs('[data-slider]')

    if (!slider) return

    const container = qs('.gallery-images')
    const links = qsa('[data-popup]')
    const next = qs('.gallery .controls-next')
    const prev = qs('.gallery .controls-prev')
    const images = qsa('.gallery-image')

    var options = {
      container: container,
      element: slider,
      links: links,
      easing: 0.075,
      duration: 100,
      dragSpeed: 1.75,
      next: next,
      prev: prev,
      images: images,
    }

    if (!store.sniff.isDevice) {
      this.slider = new Slider(options)
    } else {
      slider.parentNode.classList.add('-isDevice')
    }
  }

  building() {
    const build = qs('.heroHome-building')

    if (!build) return

    const floors = qsa('.front', build)

    floors.forEach((floor) => {
      floor.addEventListener('mouseenter', (e) => {
        const target = e.target
        const id = target.dataset.floor
        const info = qs(`[data-info="${id}"]`, build)

        if (store.breakpoints.L) {
          gsap.to(info, { autoAlpha: 1, duration: 0.3 })
        }
      })

      floor.addEventListener('mouseleave', (e) => {
        const target = e.target
        const id = target.dataset.floor
        const info = qs(`[data-info="${id}"]`, build)
        if (store.breakpoints.L) {
          gsap.to(info, { autoAlpha: 0, duration: 0.3 })
        }
      })

      floor.addEventListener('click', (e) => {
        const target = e.target.parentNode
        const link = target.dataset.link

        this.core.taxi.navigateTo(link)
      })
    })
  }

  tabs() {
    const tabs = qsa('[data-tab]')
    const contents = qsa('[data-tab-content]')

    tabs.forEach((tab) => {
      tab.addEventListener('click', (e) => {
        const target = e.target
        const id = target.dataset.tab
        const current = qs(`[data-tab-content="${id}"]`)

        tabs.forEach((tab) => {
          tab.classList.remove('-active')
        })

        contents.forEach((content) => {
          content.classList.remove('-active')
        })

        target.classList.add('-active')
        current.classList.add('-active')
      })
    })
  }

  hotspots() {
    const { hospots } = this.dom

    if (!hospots) return

    const hotspotsButton = qs('.-button', hospots)
    const hotspotsSpots = qs('.hotspots-spots', hospots)
    const spotItem = qsa('.spot-item', hospots)
    const detailItem = qsa('.hotspots-details', hospots)
    const title = qs('.hotspots-title', hospots)
    const box = qs('.hotspots-box', hospots)

    hotspotsButton.addEventListener('click', (e) => {
      gsap.to(hotspotsSpots, { autoAlpha: 1, duration: 0.3 })
      gsap.to(box, { autoAlpha: 1, duration: 0.3 })
      gsap.to(title, { autoAlpha: 0, duration: 0.3 })
      gsap.to(hotspotsButton, { autoAlpha: 0, duration: 0.3 })
    })

    spotItem.forEach((item) => {
      item.addEventListener('click', (e) => {
        const target = e.target
        const id = target.dataset.spot
        const boxDetail = qs(`[data-detail="${id}"]`)

        spotItem.forEach((item) => {
          item.classList.remove('-active')
        })

        detailItem.forEach((item) => {
          item.classList.remove('-active')
        })
        target.classList.add('-active')
        boxDetail.classList.add('-active')

        gsap.to('.hotspots-details', {
          autoAlpha: 0,
          y: 20,
          duration: 0.5,
          onComplete: () => {
            gsap.to(boxDetail, { autoAlpha: 1, y: 0, duration: 0.5 })
          },
        })
      })
    })
  }

  accordion() {
    const tabs = qsa('[data-tab-title]')

    tabs.forEach((tab) => {
      tab.addEventListener('click', (e) => {
        const target = e.target
        const parent = target.parentNode
        const content = qs('[data-tab-content]', parent)
        const p = qs('p', content)

        target.classList.toggle('-active')
        content.classList.toggle('-active')
        gsap.fromTo(
          p,
          { autoAlpha: 0, y: 20 },
          { autoAlpha: 1, y: 0, ease: 'power2.out' },
        )
      })
    })
  }

  tick = ({ scroll }) => {
    this.scroll = scroll.current
    this.direction = scroll.direction > 0 ? 'down' : 'up'

    this.handleMenu()
  }

  handleMenu() {
    if (
      this.scroll > 100 &&
      this.direction == 'down' &&
      !this.state.header &&
      !store.sniff.isDevice
    ) {
      if (this.state.animate) return

      this.state.animate = true
      gsap.to('.header', {
        duration: 0.6,
        autoAlpha: 0,
        onComplete: () => {
          this.state.animate = false
        },
      })

      this.state.header = true
    }

    if (this.direction == 'up' && this.state.header) {
      if (this.state.animate) return

      this.state.animate = true

      gsap.to('.header', {
        duration: 0.6,
        autoAlpha: 1,
        onComplete: () => {
          this.state.animate = false
        },
      })
      this.state.header = false
    }
  }

  smooth() {
    formFocus()
    growTextarea()
    const smooth = qsa('[data-s-i]', this.el)
    this.core.scroll.setScrollBounds()
    Smooth.init(smooth)
  }

  on() {
    Emitter.on('tick', this.tick)
    Emitter.on('loaded', this.ready)
    Emitter.on('animateIn:ended', this.readyCompleted)
  }

  off() {
    Emitter.off('tick', this.tick)
    Emitter.off('loaded', this.ready)
    Emitter.on('animateIn:ended', this.readyCompleted)
  }

  onLeave() {
    this.off()
    this.menu.closeMenu()
    this.menu && this.menu.destroy()
    this.player && this.player.destroy()
    this.slider && this.slider.destroy()
  }

  onLeaveCompleted() {
    ScrollTrigger.getAll().forEach((inst) => inst.kill(true))
    document.body.classList.remove(this.period)
  }
}
