import store from '../store'
import * as Taxi from '@unseenco/taxi'
import { Page } from '../renders'
import { Fade } from '../transitions'
import {
  Emitter,
  GlobalVRaf,
  GlobalRaf,
  Scroll,
  VScroll,
  Mouse,
  GlobalResize,
  AssetLoader,
  AnimationManager,
} from './'

let singleton = null

export default class Core {
  constructor() {
    if (!singleton) {
      singleton = this
    } else {
      return singleton
    }

    this.ready = false

    // Store
    this.store = store

    // Taxi
    this.taxi = new Taxi.Core({
      links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ilc-layout a)',

      renderers: {
        default: Page,
        page: Page,
      },

      transitions: {
        default: Fade,
      },
    })

    // Global Request Animation Frame
    //this.raf = new GlobalVRaf() // When using Custom Scroll
    this.raf = new GlobalRaf() // When using Lenis
    // Global Scroll
    //this.scroll = new VScroll({ smooth: true }) // Custom Smooth
    this.scroll = new Scroll() // Lenis
    // Global Mouse
    this.mouse = new Mouse()
    // Global Resize
    this.resize = new GlobalResize()
    // Asset Loader (Needs a data file data.yml)
    this.loader = new AssetLoader()

    this.init()
  }

  init() {
    // Initialize core methods
    this.raf.on()
    this.scroll.on()
    this.mouse.on()

    this.loader.on().then((responses) => {
      this.ready = true
      Emitter.emit('loaded')
    })
  }
}
