import store from '../store'
import { qs, qsa } from '../utils'
import gsap from 'gsap'

export const animateScroll = (animation) => {
  switch (animation) {
    case 'building':
      const floorsMobile = qsa('.heroHome-building-crop.mobile .front')
      const floorsDesktop = qsa('.heroHome-building-crop.desktop .front')
      const floorsInfo = qsa('.heroHome-floors .floor-info')
      const total = floorsDesktop.length - 1
      let flag

      let mm = gsap.matchMedia()

      floorsInfo.forEach((floor, index) => {
        mm.add('(max-width: 1024px)', () => {
          gsap.to(floorsMobile[index], {
            scrollTrigger: {
              trigger: floor,
              start: 'top center',
              end: 'bottom bottom',
              scrub: true,
            },
            autoAlpha: 1,
          })

          gsap.to(floor, {
            scrollTrigger: {
              trigger: floor,
              start: 'top center',
              end: 'bottom bottom',
              scrub: true,
            },
            autoAlpha: 1,
          })

          gsap.fromTo(
            floorsDesktop[total - index],
            {
              autoAlpha: 0,
            },
            {
              scrollTrigger: {
                trigger: floor,
                start: 'top center',
                end: 'bottom bottom',
                scrub: true,
              },
              autoAlpha: 1,
            },
          )
        })
      })

      break

    case 'stats':
      const stats = qsa('.stats-item')

      stats.forEach((stat, index) => {
        gsap.from(stat, {
          scrollTrigger: {
            trigger: stats,
            start: 'top center',
          },
          autoAlpha: 0,
          y: 20,
          stagger: 0.2,
        })
      })

      stats.forEach((stat, index) => {
        let nr = qs('.stats-number', stat)
        let to = nr.innerHTML

        gsap.fromTo(
          nr,
          {
            innerText: '00',
          },
          {
            scrollTrigger: {
              trigger: stats,
              start: 'top center',
            },
            innerText: to,
            snap: 'innerText',
          },
        )
      })

      break

    case 'textBlock':
      break

    case 'expertise':
      break

    case 'textImageBlock':
      break

    case 'textSlider':
      break

    case 'hotspots':
      break

    case 'accordion':
      break

    case 'explore':
      break

    case 'timeline':
      break

    case 'map':
      break

    case 'footer':
      break
  }
}
