import { Transition } from '@unseenco/taxi'
import { gsap } from 'gsap'

export default class Fade extends Transition {
  onLeave({ from, trigger, done }) {
    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        done()
      },
    })

    gsap.to('header', { duration: 0.3, y: '-100%' })
    tl.fromTo(from, { autoAlpha: 1 }, { duration: 0.25, autoAlpha: 0 })

    tl.play()
  }

  onEnter({ to, trigger, done }) {
    window.scrollTo(0, 0)

    const tl = gsap.timeline({
      paused: true,
      onComplete: () => {
        done()
      },
    })

    tl.fromTo(to, { autoAlpha: 0 }, { duration: 0.25, autoAlpha: 1 })

    tl.play()
  }
}
