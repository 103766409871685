import store from '../store'
import { Emitter } from '../core'
import { qs, bounds, lerp } from '../utils'
import gsap from 'gsap'

export default class Slider {
  constructor(opt = {}) {
    this.dom = {
      container: opt.container || window,
      element: opt.element,
      links: opt.links,
      next: opt.next,
      prev: opt.prev,
      images: opt.images,
      close: qs('.popup-close'),
    }

    this.options = {
      easing: opt.easing || 0.1,
      dragSpeed: opt.dragSpeed || 1,
      duration: opt.duration || 750,
    }

    this.state = {
      isMouseDown: false,
      isTranslating: false,
      currentPosition: 0,
      startPosition: 0,
      endPosition: 0,
      translation: 0,
      animationFrame: null,
      hasClick: true,
      current: 0,
      total: this.dom.images.length,
    }

    this.setupSlider()
  }

  setBoundaries() {
    const { sizes } = store
    const { element } = this.dom

    if (!element) return

    const rect = bounds(element)

    this.boundaries = {
      max: (rect.width - sizes.vw) * -1,
      min: 0,
      sliderSize: rect.width,
      referentSize: sizes.vw,
    }

    this.direction = 0
  }

  onDragEnded = (mousePosition) => {
    const { links } = this.dom

    links.forEach((link) => {
      link.style.pointerEvents = ''
    })

    setTimeout(() => {
      this.state.isDragging = false
    }, 1000)
  }

  onTranslation() {
    const { links } = this.dom
    const { isDragging } = this.state

    if (!this.state.isDragging) return

    links.forEach((link) => {
      link.style.pointerEvents = 'none'
    })
  }

  translateSlider(translation) {
    const { element } = this.dom

    if (!element) return

    translation = Math.floor(translation * 100) / 100

    gsap.set(element, { x: translation })

    if (this.translation !== translation) {
      this.onTranslation()
    }

    this.state.translation = translation
    this.state.current = this.state.translation
  }

  run = (e) => {
    this.mouse = e.mouse

    const { isDragging, isMouseDown } = this.state

    if (!this.state.isDragging && !this.state.isMouseDown)
      this.state.hasClick = false

    this.state.hasClick = true

    let translation = lerp(
      this.state.translation,
      this.state.currentPosition,
      this.options.easing,
    )

    this.translateSlider(translation)
  }

  onMouseDown = (e) => {
    const { element } = this.dom
    const mousePosition = [this.mouse.x, this.mouse.y]

    this.state.isMouseDown = true

    element.classList.add('dragged')
    this.state.startPosition = mousePosition[this.direction]
  }

  onMouseMove = () => {
    if (!this.state.isMouseDown) return

    const { startPosition, endPosition, currentPosition } = this.state
    const { min, max } = this.boundaries
    const mousePosition = [this.mouse.x, this.mouse.y]

    this.state.currentPosition =
      endPosition +
      (mousePosition[this.direction] - startPosition) * this.options.dragSpeed

    if (this.state.currentPosition < min && this.state.currentPosition > max) {
      this.state.isTranslating = true
    } else {
      this.state.currentPosition = Math.min(this.state.currentPosition, min)
      this.state.currentPosition = Math.max(this.state.currentPosition, max)
      this.state.isDragging = true
    }
  }

  onMouseUp = () => {
    const { element } = this.dom
    element.classList.remove('dragged')
    this.state.endPosition = this.state.currentPosition

    var mousePosition = [this.mouse.x, this.mouse.y]

    this.onDragEnded(mousePosition)
    this.state.isMouseDown = false
  }

  openPopup = (e) => {
    if (!this.state.hasClick) {
      e.preventDefault()
      e.stopPropagation()
      return
    }

    const target = e.target.children[0]
    const img = target.dataset.popup
    const popup = qs('.gallery-popup img')

    if (!img) return

    popup.src = img

    gsap.to('.gallery-popup', { autoAlpha: 1, duration: 0.5 })
  }

  closePopup = () => {
    gsap.to('.gallery-popup', { autoAlpha: 0, duration: 0.5 })
  }

  next = () => {
    const { max, sliderSize } = this.boundaries
    const { currentPosition, total } = this.state

    this.state.current -= currentPosition > max ? sliderSize / total : 0
    this.state.currentPosition = Math.max(this.state.current, max)
    this.state.endPosition = this.state.currentPosition
  }

  prev = () => {
    const { min, sliderSize } = this.boundaries
    const { currentPosition, total } = this.state

    this.state.current += currentPosition < min ? sliderSize / total : 0
    this.state.currentPosition = Math.min(this.state.current, min)
    this.state.endPosition = this.state.currentPosition
  }

  onResize = () => {
    const { element } = this.dom
    var ratio = this.state.translation / this.boundaries.sliderSize
    this.setBoundaries()

    element.style.transform = 'tanslate3d(0, 0, 0)'
    var newTranslation = ratio * this.boundaries.sliderSize
    newTranslation = Math.min(newTranslation, this.boundaries.min)
    newTranslation = Math.max(newTranslation, this.boundaries.max)
    this.translateSlider(newTranslation)

    this.state.currentPosition = newTranslation
    this.state.endPosition = newTranslation
  }

  setupSlider() {
    this.setBoundaries()

    const { container, next, prev, close } = this.dom

    // container.addEventListener('mousedown', this.onMouseDown)
    // container.addEventListener('mousemove', this.onMouseMove, {
    //   passive: true,
    // })
    // container.addEventListener('touchstart', this.onMouseDown, {
    //   passive: true,
    // })
    // container.addEventListener('touchmove', this.onMouseMove, {
    //   passive: true,
    // })
    // container.addEventListener('mouseup', this.onMouseUp)
    // container.addEventListener('touchend', this.onMouseUp)

    container.addEventListener('click', this.openPopup)
    close && close.addEventListener('click', this.closePopup)

    next.addEventListener('click', this.next)
    prev.addEventListener('click', this.prev)

    Emitter.on('resize', this.onResize)
    Emitter.on('tick', this.run)
  }

  destroy() {
    const { container, next, prev, close } = this.dom

    container.removeEventListener('mousedown', this.onMouseDown)
    container.removeEventListener('mousemove', this.onMouseMove, {
      passive: true,
    })
    container.removeEventListener('touchstart', this.onMouseDown, {
      passive: true,
    })
    container.removeEventListener('touchmove', this.onMouseMove, {
      passive: true,
    })
    container.removeEventListener('mouseup', this.onMouseUp)
    container.removeEventListener('touchend', this.onMouseUp)

    container.removeEventListener('click', this.openPopup)
    close && close.removeEventListener('click', this.closePopup)

    next.removeEventListener('click', this.next)
    prev.removeEventListener('click', this.prev)

    Emitter.off('resize', this.onResize)
    Emitter.off('tick', this.run)
  }
}
