import gsap from 'gsap'
import { Emitter } from '../core'
import { qs, qsa } from '../utils'

export const animateIn = (animation) => {
  switch (animation) {
    case 'hero':
      const heroBtn = qs('.hero .-button')

      const hero = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      gsap.set('section', { autoAlpha: 1 })

      hero.from('.hero .-asset-crop', {
        y: '-101%',
        ease: 'power3.inOut',
        duration: 0.8,
      })
      hero.from(
        '.hero .player-video',
        { y: '101%', ease: 'power3.inOut', duration: 0.8 },
        '-=0.8',
      )

      hero.from('.hero-title .line-0', {
        duration: 0.6,
        y: 40,
        autoAlpha: 0,
        stagger: 0.1,
      })

      heroBtn &&
        hero.from(
          heroBtn,
          {
            duration: 0.6,
            y: 40,
            autoAlpha: 0,
          },
          '-=0.4',
        )

      hero.fromTo('header', { y: '-100%' }, { duration: 0.6, y: '0%' }, '-=0.6')

      hero.play()

      break

    case 'heroHome':
      const heroHome = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      gsap.set('section', { autoAlpha: 1 })

      heroHome.from('.heroHome-title .line-0', {
        duration: 0.6,
        y: 40,
        autoAlpha: 0,
        stagger: 0.1,
      })

      heroHome.from(
        '.heroHome-description',
        {
          duration: 0.6,
          y: 40,
          autoAlpha: 0,
        },
        '-=0.4',
      )

      heroHome.from(
        '.heroHome-scroll',
        {
          duration: 0.6,
          y: 40,
          autoAlpha: 0,
        },
        '-=0.4',
      )

      heroHome.fromTo(
        'header',
        { y: '-100%' },
        { duration: 0.6, y: '0%' },
        '-=0.6',
      )

      heroHome.play()

      break

    case 'heroLinecard':
      const heroLinecard = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      gsap.set('section', { autoAlpha: 1 })

      heroLinecard.from('.heroLinecard-title .line-0', {
        duration: 0.6,
        y: 40,
        autoAlpha: 0,
        stagger: 0.1,
      })

      heroLinecard.from(
        '.heroLinecard .-p3',
        {
          duration: 0.6,
          y: 40,
          autoAlpha: 0,
        },
        '-=0.4',
      )

      heroLinecard.from(
        '.galleryTab-container',
        {
          duration: 0.6,
          y: 40,
          autoAlpha: 0,
        },
        '-=0.4',
      )

      heroLinecard.fromTo(
        'header',
        { y: '-100%' },
        { duration: 0.6, y: '0%' },
        '-=0.6',
      )

      heroLinecard.play()

      break

    case 'heroContact':
      const heroContact = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      gsap.set('section', { autoAlpha: 1 })

      heroContact.from('.heroContact-title .line-0', {
        duration: 0.6,
        y: 40,
        autoAlpha: 0,
        stagger: 0.1,
      })

      heroContact.from(
        '.heroContact-solution p',
        {
          duration: 0.6,
          y: 40,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=0.4',
      )

      heroContact.from(
        '.heroContact-join p',
        {
          duration: 0.6,
          y: 40,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=0.4',
      )

      heroContact.from(
        '.heroContact-social p',
        {
          duration: 0.6,
          y: 40,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=0.4',
      )

      heroContact.from(
        '.heroContact-social li',
        {
          duration: 0.6,
          y: 40,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=0.6',
      )

      heroContact.fromTo(
        'header',
        { y: '-100%' },
        { duration: 0.6, y: '0%' },
        '-=0.6',
      )

      heroContact.play()

      break

    case 'stats':
      const statsItems = qsa('.stats-item')
      const stats = gsap.timeline({
        paused: true,
        onComplete: () => {
          Emitter.emit('animateIn:ended')
        },
      })

      gsap.set('section', { autoAlpha: 1 })

      stats.fromTo(
        'header',
        { y: '-100%' },
        { duration: 0.6, y: '0%' },
        '-=0.6',
      )

      stats.from('.stats-title .line-0', {
        duration: 0.6,
        y: 40,
        autoAlpha: 0,
        stagger: 0.1,
      })

      stats.from(
        '.stats-item',
        {
          duration: 0.6,
          y: 40,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=0.4',
      )

      statsItems.forEach((item, index) => {
        let nr = qs('.stats-number', item)
        let to = nr.innerHTML

        stats.fromTo(
          nr,
          {
            innerText: '00',
          },
          {
            innerText: to,
            snap: 'innerText',
            stagger: 0.1,
          },
          '-=0.5',
        )
      })

      stats.play()

      break
  }
}
